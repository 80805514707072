<!--
File: DeuEditForm.vue
Description: form for adding/editing a single DEU.
-->
<template>
  <form>
    <md-card>
      <md-card-content>
        <div class="md-layout-item md-small-size-100 md-size-50">
          <RegionsDropdown :class="[{ 'md-valid': !errors.has('fk_region') }, { 'md-error': errors.has('fk_region') }]"
            :label="$t('road_network.region')" v-model="fk_region" data-vv-name="fk_region"
            v-validate="modelValidations.fk_region" :initial_value="fk_region" :is_required="true" />

          <md-field :class="[{ 'md-valid': !errors.has('description') }, { 'md-error': errors.has('description') }]">
            <label :for="description">{{ $t('stdCols.name_ru') }}</label>
            <div style="display: flex; align-items: center;">
              <span>ДЭУ-</span>
              <md-input :id="description" v-model="description" type="text" required data-vv-name="description"
                v-validate="modelValidations.description" />
            </div>
          </md-field>

          <md-field
            :class="[{ 'md-valid': !errors.has('description_en') }, { 'md-error': errors.has('description_en') }]">
            <label :for="description_en">{{ $t('stdCols.name_en') }}</label>
            <div style="display: flex; align-items: center;">
              <span>DEU-</span>
              <md-input :id="description_en" v-model="description_en" type="text" required data-vv-name="description_en"
                v-validate="modelValidations.description_en" style="flex: 1; border-left: none;" />
            </div>
          </md-field>

          <md-field :class="[{ 'md-valid': !errors.has('address1') }, { 'md-error': errors.has('address1') }]">
            <label :for="address1">{{ $t('stdCols.address') }} (1)</label>
            <md-input :id="address1" v-model="address1" type="text" required data-vv-name="address1"
              v-validate="modelValidations.address1" />
          </md-field>

          <md-field :class="[{ 'md-valid': !errors.has('address2') }, { 'md-error': errors.has('address2') }]">
            <label :for="address2">{{ $t('stdCols.address') }} (2)</label>
            <md-input :id="address1" v-model="address2" type="text" data-vv-name="address2" />
          </md-field>

          <md-field :class="[{ 'md-valid': !errors.has('city') }, { 'md-error': errors.has('city') }]">
            <label :for="city">{{ $t('road_network.city') }}</label>
            <md-input :id="city" v-model="city" type="text" required data-vv-name="city"
              v-validate="modelValidations.city" />
          </md-field>
        </div>

        <span v-if="saveBtnDisabled" class="md-error">
          {{ $t('messages.errors_count', { count: errors.count() }) }}
        </span>

        <div class="md-layout md-size-50">
          <div class="md-layout-item md-size-100 text-right">
            <md-button v-if="isBtnAllowed('SaveButton')" class="md-success" native-type="submit"
              @click.native.prevent="validate" :disabled="saveBtnDisabled">
              {{ $t('buttons.save') }}
            </md-button>
            <md-button class="md-accent" @click.stop.prevent="onClose">
              {{ $t('buttons.close') }}
            </md-button>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </form>
</template>
<script>
  import { mapActions } from 'vuex'
  import { onClose } from '@/mixins/onCloseMixin'
  import messages from '@/mixins/messagesMixin'
  import permissions from "@/mixins/permissionsMixin"
  import RegionsDropdown from '../Dropdowns/RegionsDropdown.vue'

  export default {
    name: 'deu-edit-form',
    mixins: [messages, permissions],

    data() {
      return {
        formName: 'DeuEditForm',

        deu_id: null,
        fk_region: null,
        description: null,
        description_en: null,
        address1: null,
        address2: null,
        city: null,

        modelValidations: {
          fk_region: { required: true, numeric: true, min_value: 1 },
          description: { required: true, min: 1 },
          description_en: { required: true, min: 1 },
          address1: { required: true, min: 3 },
          //address2: { required: false, min: 3 },
          city: { required: true, min: 3 },
        }
      }
    },

    props: {
      oper: String
    },

    components: {
      RegionsDropdown,
    },

    mounted() {
      // Check if we are eligible to view the form
      if (!this.checkIfScreenAllowed()) {
        this.onClose()
        return
      };

      const { deu_id = null } = this.$route.params
      this.deu_id = deu_id
      if (this.oper === 'upd' && deu_id) {
        //this.$store.dispatch('LOAD_DEU_BY_ID', deu_id).then((res) => {
        this.loadDeu(deu_id).then((res) => {
          const theDeu = Array.isArray(res) ? res[0] : res
          this.fk_region = theDeu.fk_region
          this.description = theDeu.description
          this.description_en = theDeu.description_en
          this.address1 = theDeu.address1
          this.address2 = theDeu.address2
          this.city = theDeu.city
        })
      }
      this.$nextTick(() => {
        this.$validator.validateAll()
      })
    },

    methods: {
      ...mapActions({
        loadDeu: 'LOAD_DEU_BY_ID',
        addDeu: 'ADD_DEU',
        editDeu: 'UPD_DEU'
      }),

      onClose,

      async validate() {
        const isValid = await this.$validator.validateAll();
        if (!isValid) return

        const theDeu = {
          fk_region: this.fk_region,
          description: this.description,
          description_en: this.description_en,
          address1: this.address1,
          address2: this.address2 ?? '',
          city: this.city
        };

        let errDesc = ''
        try {
          this.oper === 'add' ? await this.addDeu(theDeu) : await this.editDeu({ id: this.deu_id, theDeu })
        } catch (err) {
          errDesc = err.message
        }

        this.savedMessage(errDesc, this.$t('road_network.dep'), `${this.$t('road_network.dep')}-${this.description}`)

        this.$nextTick(() => {
          this.$validator.reset()
        })
        this.onClose();
      }
    },

    computed: {
    }
  }
</script>
<style>
.md-card {
  margin: 0px 0;
}

.md-button+.md-button {
  margin-left: 10px;
}
</style>